import { render, staticRenderFns } from "./ReactivationStripeForm.vue?vue&type=template&id=19fbde6f&scoped=true&"
import script from "./ReactivationStripeForm.vue?vue&type=script&lang=js&"
export * from "./ReactivationStripeForm.vue?vue&type=script&lang=js&"
import style0 from "./ReactivationStripeForm.vue?vue&type=style&index=0&id=19fbde6f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19fbde6f",
  null
  
)

export default component.exports